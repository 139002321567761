import * as THREE from "three";
import store from "../../../../store";
import {snapshot} from "valtio";

import updateThreeViewerByProduct from "../../../product/updateThreeViewerByProduct";

import tm from "../../../tm";
// Create reusable vectors
const v1 = new THREE.Vector3(0, 0, 1);
const centerVector = new THREE.Vector3(0, 0, 0);

export default async function animateTo(sectionId, props = {}) {
    tm("animateTo");
    return new Promise(async (resolve, reject) => {
        const snap = snapshot(store);
        const isAddToCart = snap.status.isAddToCart;
        tm("animateTo-1");
        const threeViewer = window.threeViewer;
        let {controls, scene, product, center, isMobile} = threeViewer;
        
        
        const SectionData = snap.sections.find(section => section.section_id === sectionId)
        const selectedProductSKU = snap.selectedProductSKU;
        
        
        let isAnimation = isAddToCart ? false : (props.duration !== undefined ? props.duration > 0 : true);
        let duration = props.duration !== undefined ? props.duration : 0.25;
        tm("animateTo-2");
        if (selectedProductSKU && selectedProductSKU !== SectionData.product_sku || props.forceToProduct) {
            const sku = snap.sections.find(section => section.section_id === sectionId).product_sku;
            store.selectedSectionId = sectionId//snap.sections.filter(section => section.product_sku === sku)[0].section_id;
            store.selectedBundleProductSKU = sku;
            //product = await loadProductIntoScene(SectionData.product_sku)
            /*sku = SectionData.product_sku
            if (product.is_bundle) {
                sku = snap.selectedBundleProductSKU || product.bundle_items[0].sku;
                product = product.bundle_items.find((item) => item.sku === sku)
                
            }
            const url = product['3d_image_url'];*/
            //const product = await loadProductIntoScene(SectionData.product_sku);
            tm("animateTo-3");
            product = await updateThreeViewerByProduct(sku, true);
            tm("animateTo-3-update");
            //const handleSelectProduct = async (item) => {
            
            //}
            //product = await updateThreeViewerByProduct(SectionData.product_sku);
            //product = await updateThreeViewerByProduct(SectionData.product_sku);
            //product = await updateThreeViewerByProduct(SectionData.product_sku);
        }
        
        
        
        
        // Initial values
        tm("animateTo-4");
        const object = scene.children.find(s => s.userData.id === sectionId);
        
        if (!object) { resolve(); return }
        
        if (!props?.current) {
            
            object?.helper?.hide()
            object?.helper?.mouseUp()
        }
        tm("animateTo-5");
        
        // Get the radial angle of the object or props
        const angleSection = object?.userData?.radialAngle || props?.radialAngle || 0;
        
        
        // Rotate the vector around bottle Y axis by the specified angle
        v1.applyAxisAngle(new THREE.Vector3(0, 1, 0), angleSection);
        
        tm("animateTo-6");
        // Calculate the center of the bounding box of the section
        object?.geometry?.computeBoundingBox();
        let bBox = object?.geometry?.boundingBox;
        bBox.getCenter(centerVector);
        tm("animateTo-7");
        // Calculate the desired camera position based in the center and the rotated vector
        let cameraPos = center.clone().add(v1);
        
        const fit = props?.fit ? props?.fit : false;
        window.threeViewer.fitToSection = fit;
        
        let target = controls.getTarget().clone()
        
        
        const options = props?.options || {
            paddingTop: fit ? 0.05 : 0.03,
            paddingBottom: fit ? 0.03 : 0.06,
            paddingLeft: 0.04,
            paddingRight: 0.04,
        }
        
        
        const fitObject = props?.fit ? object : product
        tm("animateTo-8");
        controls.smoothTime = isAddToCart ? 0 : duration / 2
        // Target X & Z should be 0 for center rotation
        controls.setLookAt(cameraPos.x, cameraPos.y, cameraPos.z, 0, target.y, 0, isAnimation).then(() => {
            tm("animateTo-9");
        })
        tm("animateTo-10");
        controls.smoothTime = isAddToCart ? 0 : duration / 1.5
        
        // To center the object in the screen
        // Remove the bracket and other things from the calculation of the box
        let box = new THREE.Box3().setFromObject(product)
        // Find max value from min and max X and Z
        let maxWidth = Math.max(box.max.x, box.max.z, Math.abs(box.min.x), Math.abs(box.min.z))
        box.max.x = box.max.z = maxWidth
        box.min.x = box.min.z = -maxWidth
        if(fitObject === product) {
            let productBox = new THREE.Box3().setFromObject(product)
            options.productHeight = productBox.max.y - productBox.min.y
            options.productCenter = productBox.getCenter(new THREE.Vector3())
        }
        tm("animateTo-11");
        fitToRect(object, maxWidth, options, isAnimation, isAddToCart).then(() => {
            tm("animateTo-12");
            resolve()
            return true
        })
    })
}




const _centerPosition = new THREE.Vector3();
const _normal = new THREE.Vector3();
const _cameraPosition = new THREE.Vector3();

const fitToRect = (rect, inMaxWidth, options, isAnimation=true, isAddToCart =false) => {
    return new Promise((resolve, reject) => {
        
        let rectDim = {
            width: Math.max(rect.size.x, inMaxWidth),
            height: options.productHeight || rect.size.y,
            center: _centerPosition.copy(rect.point)
        }
        
        let controls = window.threeViewer.controls
        rect.updateMatrixWorld();
        rectDim.center.y = options.productCenter ? options.productCenter.y : rectDim.center.y
        let quaternion = new THREE.Quaternion();
        quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), rect.userData.radialAngle);

        const rectNormal = _normal.set(0, 0, 1).applyQuaternion(quaternion);
        addPadding(rectDim, options)
        rectNormal.multiplyScalar(-1)
        const distance = controls.getDistanceToFitBox(rectDim.width, rectDim.height, 0);
        const cameraPosition = _cameraPosition.copy(rectNormal).multiplyScalar(-distance).add(rectDim.center);
        
        
        controls.setLookAt(
            cameraPosition.x, cameraPosition.y, cameraPosition.z,
            0, rectDim.center.y, 0,
            isAnimation,
        ).then((e) => {
            delay100ms(isAddToCart ? 0 : 300).then(e=> {
                resolve()
            })
        })
    })
}
function delay100ms(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}


function addPadding(rectDim, padding) {
    rectDim.height += padding.paddingTop;
    rectDim.center.y += padding.paddingTop / 2;

    rectDim.width += padding.paddingLeft
    rectDim.center.x -= padding.paddingLeft / 2;

    rectDim.width += padding.paddingRight
    rectDim.center.x += padding.paddingRight / 2;

    rectDim.height += padding.paddingBottom
    rectDim.center.y -= padding.paddingBottom / 2;
}


// function addDummyPlane(rectWidth, rectHeight, rectCenterPosition, rectNormal, inColor = 0xff0000) {

//     while (window.threeViewer.scene.getObjectByName('fitToRect-red') && inColor === 0xff0000) {
//         window.threeViewer.scene.remove(window.threeViewer.scene.getObjectByName('fitToRect-red'))
//     }
//     while (window.threeViewer.scene.getObjectByName('fitToRect-green') && inColor === 0x00ff00) {
//         window.threeViewer.scene.remove(window.threeViewer.scene.getObjectByName('fitToRect-green'))
//     }


//     let rectMesh = new THREE.Mesh(
//         new THREE.BoxGeometry(rectWidth, inColor === 0x00ff00 ? rectHeight+.001 : rectHeight, 0.001),
//         new THREE.MeshBasicMaterial({ color: inColor, transparent: true, opacity: 0.5, side: THREE.DoubleSide })
//     )
//     rectMesh.name = 'fitToRect' + (inColor === 0x00ff00 ? '-green' : '-red')

//     rectMesh.position.copy(rectCenterPosition)
//     if (inColor === 0xff0000) {
//         rectMesh.renderOrder = 1
//         rectMesh.material.depthTest = false
//     }
//     // rectMesh.visible = false
//     rectMesh.lookAt(rectCenterPosition.clone().add(rectNormal))
//     window.threeViewer.scene.add(rectMesh)
// }