// Initialize a timestamp variable in the window object
window.lastTime = null;
window.startTime = null;

function tm(title) {
    return;
    const currentTime = Date.now();
    
    if (window.lastTime === null) {
        // First time calling the function, just set the start and last time
        window.lastTime = currentTime;
        window.startTime = currentTime;
        console.log(`${title} ---- Tracker started, Total time from start: 0s`);
    } else {
        // Calculate the difference in seconds
        const timeDiff = ((currentTime - window.lastTime) / 1000).toFixed(2);
        const totalTime = ((currentTime - window.startTime) / 1000).toFixed(2);
        console.log(`-> : "${timeDiff}" from "${totalTime} ---- ${title}"`);
        
        // Update the last time to the current one
        window.lastTime = currentTime;
    }
}

function resetTracker() {
    window.lastTime = null;
    window.startTime = null;
    console.log('Tracker has been reset.');
}

export { resetTracker };
export default tm;