import * as THREE from "three";
import tm from "../../tm";
const cache = new Map();

const getColorObjectOfLargestChild = async (product, path) => {
    tm("getColorObjectOfLargestChild - start function ");
    if (cache.has(path)) {
        tm("getColorObjectOfLargestChild - from cache end function ");
        return cache.get(path);
    }
    
    let largestChild = null;
    let maxVolume = -1;
    
    product.traverse((child) => {
        // Check if the child is a mesh
        if (child.isMesh) {
            
            const boundingBox = new THREE.Box3().setFromObject(child);
            const size = new THREE.Vector3();
            boundingBox.getSize(size);
            const volume = size.x * size.y * size.z;
            // Compare the volume to find the largest mesh
            if (volume > maxVolume) {
                largestChild = child;
                maxVolume = volume;
            }
        }
    });
    
    let returnObj = {};
    if (largestChild && largestChild.material && largestChild.material.color) {
        //const HSL = await largestChild.material.color.getHSL({})
        //const color = await getMeshAverageColor(largestChild)
        const {HSL, hex} = await getMeshAverageColor(largestChild)//rgbToHsl({...color})
        
        const isLight = HSL.l > 0.5 //0.7
        
        //console.log("isLight", isLight, HSL.l)
        let light = 0
        let metalness = 0.25
        let roughness = 3
        let lightContrast = 1
        
        const HSLLight = HSL.l //- (largestChild.material.metalness/3)
        
        if (HSLLight > 0.7) {
            light = HSL.l - 0.5
            metalness = 0.25
            roughness = 2
            lightContrast = 0.1
        }
        else if (HSLLight > 0.6) {
            light = HSL.l - 0.3
            metalness = 0.35
            roughness = 2.5
            lightContrast = 0.15
        }
        else if (HSLLight > 0.5) {
            light = HSL.l + 0.3
            metalness = 0.35
            roughness = 2.5
            lightContrast = 0.15
        }
        else if (HSLLight > 0.3) {
            light = HSL.l + 0.9
            metalness = 0.4
            roughness = 3
            lightContrast = 0.2
        }
        else if (HSLLight > 0.2) {
            
            light = HSL.l + 0.9
            metalness = 0.6
            roughness = 3
            lightContrast = 0.4
        } else if (HSLLight > 0.1) {
            
            light = HSL.l + 0.9
            metalness = 0.5
            roughness = 3
            lightContrast = 0.5
            
        } else {
            light = HSL.l + 0.8
            metalness = 0.7
            roughness = 3
            lightContrast = 1
        }
        light = 1- (HSL.l/2)//1- HSL.l
        metalness = 1
        roughness = 0
        //lightContrast = HSL.l
         /*light =
            isLight ?
                HSL.l - 0.5
                :
                HSL.l + 0.5*/
        returnObj = {
            color: '#' + largestChild.material.color.getHexString(),
            isLight: isLight,
            lightContrast: lightContrast,
            //reverseColor: isLight ? "#000000" : "#ffffff",
            //object : largestChild,
            //product:product,
            //compareArray: compareArray,
            materialValues: {
                //color:hslToHex(0,0,isLight ? HSL.l  - 0.1 : HSL.l + 0.1 ),
                color: hslToHex({
                    h: 0,
                    s: 0,
                    l: light
                }),
                metalness: metalness, // isLight ? 0.25 : 0.8,
                hsl: HSL,
                rbg: hex,
                roughness: roughness, //3, //3
            }
        };
        
    }
    cache.set(path, returnObj);
    tm("getColorObjectOfLargestChild - end function ");
    return returnObj
}

function hslToHex({h, s, l}) {
    
    if (l> 1) l = 1;
    if (l< 0) l = 0;
    
    const a = s * Math.min(l, 1 - l);
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `0x${f(0)}${f(8)}${f(4)}`;
}

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

const getMeshAverageColor = async (mesh) => {
    if (mesh.material.map && mesh.material.map.source) {
        // Mesh has a texture
        var image = mesh.material.map.source.data;
        
        canvas.width = 10//image.width;
        canvas.height = 10//image.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0);
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        
        
        const r = data[0];
        const g = data[1];
        const b = data[2];
        
        return {
            HSL: rgbToHsl({
                r: r,
                g: g,
                b: b
            }),
            hex: '#' + mesh.material.color.getHexString()
        }
        
    } else if (mesh.material.color) {
        // Mesh has a solid color
        return {
            HSL: mesh.material.color.getHSL({}),
            hex: '#' + mesh.material.color.getHexString()
            
        }
        
    } else {
        // No texture or color
        return rgbToHsl({
            r: 100,
            g: 100,
            b: 100
        });
    }
}






function rgbToHsl({r, g, b}) {
    // Convert RGB to fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;
    
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    
    if (max === min) {
        h = s = 0; // Achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        
        h /= 6;
    }
    
    return { h: h, s: s, l: l };
}



export default getColorObjectOfLargestChild;