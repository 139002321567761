import store from "../../store";
import loadProductIntoScene from "../internal/loadProduct";
import {snapshot} from "valtio";
import tm from "../tm";

export default async function updateThreeViewerByProduct(productSKU) {
    const snap = snapshot(store);
    let sku = productSKU
    let product = snap.product?.find((p) => p.sku === sku);
    const loadingLastState = snap.status.loading;
    store.status.loading = true;
    store.selectedProductSKU = sku;
    
    if (product.is_bundle) {
        sku = snap.selectedBundleProductSKU || product.bundle_items[0].sku;
    }
    tm("updateThreeViewerByProduct - start");
    const assignedProduct = await loadProductIntoScene(sku);
    tm("updateThreeViewerByProduct - end");
    
    store.status.loading = loadingLastState;
    return assignedProduct;
    
    
}