import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import tm from "../tm";

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

const cache = new Map();

export function loadGLB(inPath) {
    tm("loadGLB-----");
    return new Promise((res, rej) => {
        // Check if the GLB file is already in cache
        if (cache.has(inPath)) {
            const cachedBase64 = cache.get(inPath);
            loadGLBFromBase64(cachedBase64).then(res).catch(rej);
            tm('Loading GLB from cache');
            return;
        }
        
        // Fetch the GLB file as a base64 string if not cached
        fetch(inPath)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.arrayBuffer();
        })
        .then((buffer) => {
            console.log('File fetched successfully.');
            const base64 = arrayBufferToBase64(buffer);
            cache.set(inPath, base64);
            return loadGLBFromBase64(base64);
        })
        .then(res)
        .catch(error => {
            console.error('An error happened while fetching or loading the GLB:', error);
            rej(error);
        });
    });
}

function loadGLBFromBase64(base64) {
    return new Promise((res, rej) => {
        tm("loadGLB----- 2");
        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);
        const binaryString = atob(base64);
        const len = binaryString.length;
        const arrayBuffer = new ArrayBuffer(len);
        const uintArray = new Uint8Array(arrayBuffer);
        
        for (let i = 0; i < len; i++) {
            uintArray[i] = binaryString.charCodeAt(i);
        }
        
        loader.parse(arrayBuffer, '',
            (gltf) => {
                gltf.scene.traverse((child) => {
                    if (child.isMesh) {
                        child.material = child.material.clone(); // Clone material to avoid shared references
                        child.material.transparent = true;
                    }
                });
                gltf.scene.name = 'Neatolabs';
                gltf.scene.traverse((node) => {
                    if (node.isMesh) {
                        node.castShadow = true; // Enable casting shadows
                    }
                });
                tm('Loading GLB finished without cache');
                res(gltf.scene);
            },
            (error) => {
                console.error('An error happened while parsing the GLB:', error);
                rej(error);
                tm("loadGLB----- 3");
            }
        );
    });
}

function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}
