import * as THREE from "three";
import helper from "./materials/helper";
import tm from "../../tm";

const cache = new Map();

const disposeMaterial = (material) => {
    if (material.map) material.map.dispose();
    // Dispose of other textures if present
    if (material.roughnessMap) material.roughnessMap.dispose();
    if (material.metalnessMap) material.metalnessMap.dispose();
    if (material.normalMap) material.normalMap.dispose();
    //if (material) material.dispose();
};

const steelMaterial = (colorObject, path) => {
    tm("steelMaterial-----start");
    
    
    
    
    if (window?.productColorObject?.materialList) {
        Object.values(window?.productColorObject?.materialList).forEach(material => {
            disposeMaterial(material);
        });
        window.productColorObject.materialList = null;
    }
    
    if (window?.productColorObject?.uiMaterial) {
        Object.values(window?.productColorObject?.uiMaterial).forEach(material => {
            disposeMaterial(material);
        });
        window.productColorObject.uiMaterial = null;
    }
    
    
    /*if (cache.has(path)) {
        
        const cachedReturnObject = cache.get(path);
        window.productColorObject = cachedReturnObject;
        tm("steelMaterial-----from cache");
        return cachedReturnObject;
    }*/
    
    
    const defaultValues = {
        transparent: true,
        alphaTest: 0.25,
        polygonOffset: true,
        polygonOffsetFactor: -1,
        polygonOffsetUnits: -1,
        side: THREE.FrontSide,
        alphaToCoverage: true,
        color: parseInt(colorObject?.materialValues?.color || '0x777777', 16),
    };
    
    const textureLoader = new THREE.TextureLoader();
    // Load textures once and reuse for efficiency and better memory management
    /*const roughnessMap = textureLoader.load("assets/img/textures/brushed-steel-2562.jp");
    const metalnessMap = textureLoader.load("assets/img/textures/brushed-steel-256.jp");
    const normalMap = textureLoader.load("assets/img/textures/brushed-steel-256.jpg");*/
    
    //const roughnessMap = textureLoader.load("assets/img/textures/snapLineY.png");
    const metalnessMap = textureLoader.load("assets/img/textures/metal3.jpg");
    const normalMap = textureLoader.load("assets/img/textures/Shinyit-online.jpg"); //assets/img/textures/Shinyit-online.jpg
    
    const SteelValues = {
        roughness: 0,//.05,
        metalness: 1,
        //roughnessMap,
        metalnessMap,
        //normalMap,
    };
    
    // Set texture repeats
    [metalnessMap].forEach(map => { //roughnessMap
        map.repeat.set(1, 5);
        map.wrapS = map.wrapT = THREE.RepeatWrapping;
    });
    normalMap.repeat.set(1, 3);
    normalMap.wrapS = normalMap.wrapT = THREE.RepeatWrapping;
    
    const materialList = {
        normal: new THREE.MeshStandardMaterial({ //MeshPhongMaterial //MeshStandardMaterial
            ...defaultValues,
            ...SteelValues,
            normalScale: new THREE.Vector2(0.025, 0.025),
        }),
        selected: new THREE.MeshStandardMaterial(defaultValues)
    };
    
    const returnObject = {
        ...colorObject,
        materialList: materialList,
        uiMaterial: {
            ...helper(colorObject.isLight ? 0x222222 : 0xbbbbbb)
        },
        
    };
    cache.set(path, returnObject);
    setWindowProductColorObject(returnObject);
    tm("steelMaterial-----end");
    return materialList;
    
    
}
export default steelMaterial;

const setWindowProductColorObject = (returnObject) => {
    window.productColorObject = returnObject
}